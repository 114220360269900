import { useContext, useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { Box, Card, Button, TextField, CardHeader, CardActions, CardContent, Modal, Collapse, Grid, LinearProgress } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { apiRoute } from '../../App';
import { EntityContext } from '../../context/EntityContext';
import TermsModal from '../modals/TermsModal';

const SearchForm = (props) => {
    const { setSearchResults, setLoadingSearch, setFormSubmitted, state, dispatch, ts, setCaseId, loadingSearch } = props;

    const { entity, acceptedTerms, setAcceptedTerms } = useContext(EntityContext);

    const [modal, setModal] = useState(acceptedTerms);
    const [expanded, setExpanded] = useState(false);

    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const sessionData = sessionStorage.getItem(window.location.hostname);
    let isAgreed = false;

    if (sessionData) {
        const { value, expires } = JSON.parse(sessionData);
        if (expires > new Date().getTime()) {
            setAcceptedTerms(value);
        } else {
            // If session has expired, remove it from session storage
            sessionStorage.removeItem(window.location.hostname);
            setAcceptedTerms(false);
            navigate('/');
        }
    };

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            default:
                if (!value && field !== "driverLicenseNumber") {
                    error = `Required!`;
                }
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSearchResults([]);
        const searchParams = new URLSearchParams();
        if (state.caseCitationNumber) searchParams.set('caseCitationNumber', state.caseCitationNumber)
        if (state.firstName) searchParams.set('firstName', state.firstName)
        if (state.lastName) searchParams.set('lastName', state.lastName)
        if (state.dateOfBirth) searchParams.set('dateOfBirth', state.dateOfBirth)
        if (state.driverLicenseNumber) searchParams.set('driverLicenseNumber', state.driverLicenseNumber)
        if (entity.id) searchParams.set('entityId', entity.id)

        setFormSubmitted(true);
        setLoadingSearch(true);
        await axios.get(`${apiRoute}/api/Search?${searchParams.toString()}`)
            .then(res => {
                setSearchResults(res.data.map((item) => ({
                    ...item,
                    id: item.pkViolationId
                })));
                setCaseId(res.data[0].caseId);
            })
            .catch(err => console.log(err))
            .finally(() => setLoadingSearch(false))
        await scrollToTop();
    };

    const handleReset = () => {
        handleFieldChange('caseCitationNumber', '');
        handleFieldChange('firstName', '');
        handleFieldChange('lastName', '');
        handleFieldChange('dateOfBirth', '');
        handleFieldChange('driverLicenseNumber', '');
        setFormSubmitted(false);
    };

    const handleTermsAgreement = () => {
        const expirationTime = new Date().getTime() + 3600000; // 1 hour in milliseconds
        sessionStorage.setItem(window.location.hostname, JSON.stringify({ value: true, expires: expirationTime }));
        setAcceptedTerms(true);
        setModal(false);
    };

    const handleDecline = () => {
        navigate('/');
        setModal(false);
        setAcceptedTerms(false);
    };

    const criminalCaseDisclamier = `\nOnly In-Person Payments Are Allowed On Criminal Cases At This Time.\n\nMake Payments At:\n${entity.entityName}\n${entity.streetNumber} ${entity.streetName} ${entity.city}, ${entity.state} ${entity.zip}\n\nPlease call (${entity?.phone?.substring(0, 3)}) ${entity?.phone?.substring(4)} with any questions or concerns regarding this matter.`;

    const disclaimer = (
        <>
            {
                criminalCaseDisclamier.split("\n").map((line, index) => (
                    <Fragment key={index}>
                        {line}
                        <br />
                    </Fragment>
                ))}
        </>
    );

    const handleExpandClick = () => { setExpanded(!expanded) }

    const scrollToTop = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Card sx={{ width: isSmallScreen ? '99%' : '55%', m: '.5vh auto' }} elevation={5}>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" sx={{ gap: '2rem' }}>
                        <CardHeader title="Citation Search" subheader="Enter citation info" />
                        <Box display="flex" flexDirection="column" justifyContent="center" sx={{ mr: '1vw'}}>
                            <Button
                                endIcon={<ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} fontSize="large" />}
                                onClick={handleExpandClick}
                                variant="outlined"
                                sx={{ ml: 'auto', height: '7.5vh', mr: '1vw', width: '98%'}}
                            >
                                Criminal Cases
                            </Button>
                        </Box>
                    </Box>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <hr style={{ mb: '0vh', width: '95%', marginLeft: 'auto', marginRight: 'auto' }} />
                        <CardHeader title="Criminal Cases" subheader={disclaimer} sx={{ pb: '0vh', mb: '0vh' }} />
                        <hr style={{ mb: '0vh', width: '95%', marginLeft: 'auto', marginRight: 'auto' }} />
                    </Collapse>
                    {expanded && <CardHeader title="Citation Search" subheader="Enter citation info" />}
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : null, gap: '1rem' }}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="caseCitationNumber"
                                label="Citation Number*"
                                value={state?.caseCitationNumber}
                                onChange={(e) => handleFieldChange('caseCitationNumber', e.target.value)}
                                onBlur={(e) => handleBlur('caseCitationNumber', e.target.value)}
                                helperText={state?.errors?.caseCitationNumber}
                                error={!!state?.errors?.caseCitationNumber}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                sx={ts}
                                id="firstName"
                                label="First Name*"
                                value={state?.firstName}
                                onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                onBlur={(e) => handleBlur('firstName', e.target.value)}
                                helperText={state?.errors?.firstName}
                                error={!!state?.errors?.firstName}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                sx={ts}
                                id="lastName"
                                label="Last Name*"
                                value={state?.lastName}
                                onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                onBlur={(e) => handleBlur('lastName', e.target.value)}
                                helperText={state?.errors?.lastName}
                                error={!!state?.errors?.lastName}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : null, gap: '1rem', mt: '2vh' }}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="dateOfBirth"
                                label="Date of Birth*"
                                type="date"
                                value={state?.dateOfBirth}
                                onChange={(e) => handleFieldChange('dateOfBirth', e.target.value)}
                                onBlur={(e) => handleBlur('dateOfBirth', e.target.value)}
                                helperText={state?.errors?.dateOfBirth}
                                error={!!state?.errors?.dateOfBirth}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                sx={ts}
                                id="driverLicenseNumber"
                                label="Driver License Number"
                                value={state?.driverLicenseNumber}
                                onChange={(e) => handleFieldChange('driverLicenseNumber', e.target.value)}
                                onBlur={(e) => handleBlur('driverLicenseNumber', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Box sx={{ display: 'flex', gap: '1rem', flexDirection: isSmallScreen ? 'column' : null, width: isSmallScreen ? '98%' : null, ml: isSmallScreen ? '1vw' : null, mt: isSmallScreen ? '-.5vh' : null, mb: isSmallScreen ? '.2vh' : null }}>
                            <Button variant="contained" sx={{ backgroundColor: 'indianred', color: 'white', height: isSmallScreen ? '7.5vh' : 'inherit' }} onClick={handleReset}>Clear Fields</Button>
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', height: isSmallScreen ? '7.5vh' : 'inherit' }} type="submit"
                                disabled={!state.caseCitationNumber | !state.firstName | !state.lastName | !state.dateOfBirth}
                            >
                                Search
                            </Button>
                            {loadingSearch && <LinearProgress />}
                        </Box>
                    </CardActions>
                </Card>
            </form>
            <Modal open={!acceptedTerms} sx={{ width: '90%', m: '2vh auto 0 auto', height: isSmallScreen ? '100vh' : "inherit" }}>
                <>
                    <TermsModal
                        handleSubmit={handleTermsAgreement}
                        handleDecline={handleDecline}
                        isSmallScreen={isSmallScreen}
                    />
                </>
            </Modal>
        </>
    )
};

export default SearchForm;