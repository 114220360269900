import React, { useEffect, useState, useReducer, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PaymentInfo from '../components/modals/paymentModal/PaymentInfo';
import SearchForm from '../components/search/SearchForm';
import SearchResults from '../components/search/SearchResults';

import { EntityContext } from '../context/EntityContext';
import { ThemeModeContext } from '../App';

import { Modal } from '@mui/material';

const SearchView = () => {
    const { mode } = useContext(ThemeModeContext);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const { entity } = useContext(EntityContext);

    const [searchResults, setSearchResults] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [paymentsSubmitted, setPaymentsSubmitted] = useState(false);

    const [caseId, setCaseId] = useState(null);

    const [modal, setModal] = useState(false);

    const [declinedModal, setDeclinedModal] = useState(false);

    const [payments, setPayments] = useState({});
    const [paymentData, setPaymentData] = useState({});
    const [paymentsArray, setPaymentsArray] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [fee, setFee] = useState(0);

    // state for current tab in payment info popup
    const [activeStep, setActiveStep] = useState(0);


    const handleOpen = () => setModal(true);
    const handleClose = () => {
        setPaymentsSubmitted(false);
        setModal(false);
        setActiveStep(0);
    };

    const decline = () => setDeclinedModal(true);
    const closeDecline = () => setDeclinedModal(false);

    // function for 'next' button to allow the user to go to the next tab in payment info popup
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // function for 'back' button to allow the user to go to the previous tab in payment info popup
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePaymentChange = (e, row) => {
        const { value } = e.target;

        setPayments((prevData) => ({
            ...prevData,
            [row.id]: value,
        }));

        setPaymentData((prevData) => ({
            ...prevData,
            [row.id]: {
                ...row,
                payment: parseFloat(value === "" ? '0.00' : value).toFixed(2),
            },
        }));
    };

    const handleSubmit = () => {
        const paymentValueCheck = paymentsArray.every((payment) => payment.payment === 0);

        const overPayCheck = paymentsArray.some(payment => parseFloat(payment.payment) > payment.totalBalance);

        let total = paymentsArray.reduce((total, payment) => {
            return parseFloat(total) + parseFloat(payment.payment)
        }, 0);

        if (paymentValueCheck || total === 0) {
            toast.error("Please input at least one payment amount.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (overPayCheck) {
            toast.error("Violation over payment is not accepted", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
            isSmallScreen ? setPaymentsSubmitted(true) : handleOpen();
        }
    };

    // goes through the object and pulls all of the payment amounts and adds them all together to get a total before adding the extra fee (subtotal)
    const totalPayments = paymentsArray.reduce((total, item) => {
        const payment = parseFloat(item.payment);
        return total + (isNaN(payment) ? 0 : payment);
    }, 0);

    // Format the payment amounts with appropriate decimal places
    const formattedTotalPayments = parseFloat(totalPayments).toFixed(2);
    const formattedPaymentAmount = parseFloat(paymentAmount).toFixed(2);

    // sets paymentAmount state for the PaymentAmount column in the database
    useEffect(() => {
        const feeAmount = totalPayments * entity.convenienceFeeRate;
        const roundedFee = parseFloat(feeAmount.toFixed(2));
        setFee(roundedFee);
        setPaymentAmount(totalPayments + roundedFee);
    }, [totalPayments, entity.convenienceFeeRate]);

    // takes the paymentData object and converts it to an array of objects
    useEffect(() => {
        const paymentDataArray = Object.values(paymentData);
        setPaymentsArray(paymentDataArray);
    }, [paymentData]);

    const initialState = {
        caseCitationNumber: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        driverLicenseNumber: '',
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    return (
        <>
            {
                !paymentsSubmitted &&
                isSmallScreen && formSubmitted &&
                <SearchResults
                    searchResults={searchResults}
                    loadingSearch={loadingSearch}
                    defendantName={`${state?.firstName} ${state.lastName}`}
                    ts={textFieldStyle}
                    caseId={caseId}
                    isSmallScreen={isSmallScreen}
                    setModal={setModal}
                    handlePaymentChange={handlePaymentChange}
                    payments={payments}
                    paymentsArray={paymentsArray}
                    paymentData={paymentData}
                    handleSubmit={handleSubmit}
                    setPaymentsSubmitted={setPaymentsSubmitted}
                />
            }
            {
                !paymentsSubmitted &&
                <SearchForm
                    setSearchResults={setSearchResults}
                    setLoadingSearch={setLoadingSearch}
                    setFormSubmitted={setFormSubmitted}
                    state={state}
                    dispatch={dispatch}
                    ts={textFieldStyle}
                    setCaseId={setCaseId}
                    loadingSearch={loadingSearch}
                />
            }
            {
                !paymentsSubmitted &&
                !isSmallScreen && formSubmitted &&
                <SearchResults
                    searchResults={searchResults}
                    loadingSearch={loadingSearch}
                    defendantName={`${state?.firstName} ${state.lastName}`}
                    ts={textFieldStyle}
                    caseId={caseId}
                    isSmallScreen={isSmallScreen}
                    setModal={setModal}
                    payments={payments}
                    handlePaymentChange={handlePaymentChange}
                    paymentData={paymentData}
                    handleSubmit={handleSubmit}
                    setPaymentsSubmitted={setPaymentsSubmitted}
                    paymentsArray={paymentsArray}
                />
            }
            {
                paymentsSubmitted &&
                <PaymentInfo
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    payments={payments}
                    setPayments={setPayments}
                    paymentData={paymentData}
                    setPaymentData={setPaymentData}
                    paymentsArray={paymentsArray}
                    setPaymentsArray={setPaymentsArray}
                    totalPayments={totalPayments}
                    paymentAmount={formattedPaymentAmount}
                    setPaymentAmount={setPaymentAmount}
                    handlePaymentChange={handlePaymentChange}
                    fee={fee}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    decline={decline}
                    closeDecline={closeDecline}
                    declinedModal={declinedModal}
                    entityId={entity.id}
                    defendantName={`${state?.firstName} ${state.lastName}`}
                    ts={textFieldStyle}
                    caseId={caseId}
                    isSmallScreen={isSmallScreen}
                    handleSubmit={handleSubmit}
                    entity={entity}
                    formattedTotalPayments={formattedTotalPayments}
                />
            }
            <Modal
                open={modal}
                sx={
                    {
                        width: "50%",
                        margin: "10vh auto",
                        height: isSmallScreen ? '100vh' : null
                        //backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        //backdropFilter: 'blur(10px)'
                    }
                }
            >
                <PaymentInfo
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    payments={payments}
                    setPayments={setPayments}
                    paymentData={paymentData}
                    setPaymentData={setPaymentData}
                    paymentsArray={paymentsArray}
                    setPaymentsArray={setPaymentsArray}
                    totalPayments={totalPayments}
                    paymentAmount={formattedPaymentAmount}
                    setPaymentAmount={setPaymentAmount}
                    handlePaymentChange={handlePaymentChange}
                    fee={fee}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    decline={decline}
                    closeDecline={closeDecline}
                    declinedModal={declinedModal}
                    entityId={entity.id}
                    defendantName={`${state?.firstName} ${state.lastName}`}
                    ts={textFieldStyle}
                    caseId={caseId}
                    isSmallScreen={isSmallScreen}
                    handleSubmit={handleSubmit}
                    entity={entity}
                    formattedTotalPayments={formattedTotalPayments}
                />
            </Modal >
            <ToastContainer />
        </>
    )
}

export default SearchView;