import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Skeleton, CircularProgress, Backdrop, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import SwipeUpIcon from '@mui/icons-material/SwipeUp';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import NavMenu from './navbar/NavMenu';
import { EntityContext } from '../context/EntityContext';
import { ThemeModeContext } from '../App';

import '../css/custom.css';

const Layout = ({ children, condensed }) => {
    const { mode, toggleMode } = useContext(ThemeModeContext);
    const { loading, getEntity } = useContext(EntityContext);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const { entityName } = useParams();

    const [speedDial, setSpeedDial] = useState(true);
    const [tapped, setTapped] = useState(0);

    useEffect(() => {
        if (entityName) {
            getEntity(entityName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityName]);

    const scrollToTop = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const actions = [{
        icon: mode === 'dark' ? <Brightness4Icon /> : <Brightness7Icon />,
        name: `${mode.slice(0, 1).toUpperCase().concat(mode.slice(1)) !== 'Light' ? 'Light' : 'Dark'}`,
        onClick: toggleMode
    }, {
        icon: <KeyboardDoubleArrowUpIcon />,
        name: 'Scroll Up',
        onClick: scrollToTop
    }];

    if (loading) {
        if (condensed === 'Y' || !condensed) {
            return (
                <div>
                    <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>

            )
        } else {
            return (
                <div>
                    <Skeleton animation="wave" variant="rectangle" height={60} />
                    <Box sx={{ width: '55%', m: '2.5vh auto' }}>
                        <Skeleton animation="wave" variant="rounded" height={300} />
                    </Box>
                </div>
            )
        }
    };

    const handleDoubleTap = () => {
        const currentTime = new Date().getTime();
        const tapLength = currentTime - tapped;

        if (tapLength < 300 && tapLength > 0) {
            setSpeedDial(!speedDial);
        }

        setTapped(currentTime);
    };

    return (
        <div style={{ height: '100vh' }}>
            <NavMenu />
            <div className="page" onClick={handleDoubleTap}>
                {children}
                {isSmallScreen && speedDial &&
                    <Box sx={{ position: 'fixed', bottom: 16, right: 25 }}>
                        <SpeedDial
                            ariaLabel="SpeedDial"
                            icon={<SpeedDialIcon />}
                        >
                            {actions.map((action) => {
                                return (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                        tooltipOpen
                                        tooltipPlacement={'left'}
                                        TooltipClasses={{ gap: '2rem' }}
                                        onClick={action.onClick}
                                    />
                                )
                            })}
                        </SpeedDial>
                    </Box>}
            </div>
        </div>
    );
}

export default Layout;